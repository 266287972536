import React from 'react'
import { View, Platform, StyleProp, ViewStyle } from 'react-native'
import { LinearGradient, Stop, Rect, Svg, Defs } from 'react-native-svg'

type GradientStop = {
    color: string;
    position: number;
}

export interface GradientButtonProps {
    gradientStops?: GradientStop[]
    colors?: string[]
    angle?: number
    style?: StyleProp<ViewStyle>
    children?: React.ReactNode
}

const LinearGradientComponent: React.FC<GradientButtonProps> = ({
    gradientStops,
    colors,
    angle,
    children,
    style = {},
}) => {
    const {
        padding,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingVertical,
        paddingHorizontal,
        borderRadius,
        backgroundColor, //deconstruct this to ignore it
        ...restStyle
    } = style as ViewStyle
    
    const finalGradientStops = gradientStops ? gradientStops: colors.map((color, index) => ({color: color, position: (index * 100) / (colors.length - 1)}))

    const renderWeb = ({ children }) => {
        const gradientStopsJoined = finalGradientStops.map((gradientStop: GradientStop) => `${gradientStop.color} ${gradientStop.position}%`).join(', ')
        const gradientAngle = angle? `${angle}deg`: 'to bottom'
        return (
            <div
                style={{
                    display: 'flex',
                    backgroundImage: `linear-gradient(${gradientAngle}, ${gradientStopsJoined})`,
                    //@ts-ignore
                    ...style,
                }}
            >
                {children}
            </div>
        )
    }

    const renderMobile = ({ children }) => {
        const { x1, y1, x2, y2 } = angleToCoordinates(angle)

        return (
            <View style={[restStyle]}>
                <Svg width="100%" height="100%">
                    <Defs>
                        {/* @ts-ignore */}
                        <LinearGradient id="grad" x1={x1} y1={y1} x2={x2} y2={y2}>
                            {finalGradientStops.map((gradientStop, index) => (
                                <Stop
                                    key={index}
                                    offset={`${gradientStop.position}%`}
                                    stopColor={gradientStop.color}
                                />
                            ))}
                        </LinearGradient>
                    </Defs>
                    <Rect
                        rx={borderRadius || 0}
                        ry={borderRadius || 0}
                        width="100%"
                        height="100%"
                        fill={`url(#grad)`}
                    />
                    <View
                        style={[
                            {
                                height: '100%',
                                display: 'flex',
                                ...restStyle,
                            },
                            {
                                padding,
                                paddingTop,
                                paddingBottom,
                                paddingLeft,
                                paddingRight,
                                paddingVertical,
                                paddingHorizontal,
                            },
                        ]}
                    >
                        {children}
                    </View>
                </Svg>
            </View>
        )
    }

    const angleToCoordinates = (angle: number): {x1: number; y1: number; x2: number; y2: number} => {
        const radians = (angle * Math.PI) / 180;

        // Calculate x1, y1, x2, y2
        const x1 = 0.5 - Math.cos(radians) / 2;
        const y1 = 0.5 - Math.sin(radians) / 2;
        const x2 = 0.5 + Math.cos(radians) / 2;
        const y2 = 0.5 + Math.sin(radians) / 2;

        return {x1, y1, x2, y2}
    }

    return Platform.OS === 'web'
        ? renderWeb({ children })
        : renderMobile({ children })
}

export default LinearGradientComponent
